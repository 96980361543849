.wrapper {
  @apply mt-0 md:mt-2 xl:mt-4 w-full lg:w-4/5 p-2 lg:p-4 text-justify;
  h2 {
    @apply text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold italic uppercase;
  }
  p {
    @apply text-[#B2B2B2] text-[16px] sm:text-[18px] md:text-[22px] xl:text-[24px] font-normal;
  }
}
.partnerWrapper {
  @apply flex justify-center mt-[100px];
  .boxPartner {
    @apply max-w-[1300px] w-full flex flex-col;
  }
  .boxes {
    @apply flex justify-between items-center;
    .titlePartners {
      @apply text-white text-[40px] font-semibold whitespace-nowrap;
    }

    .linePartners {
      @apply h-[2px] w-[300px] rotate-0 last:rotate-180;
      background: linear-gradient(35deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 1) 15%, rgba(3, 235, 246, 1) 100%);
    }
  }
}

.boxImagePartner {
  @apply w-full flex flex-wrap justify-center items-center my-[100px];
}

.imagePartner {
  @apply w-[125px] h-auto flex items-center m-[25px] grayscale hover:grayscale-0;
  transition: all 0.5s;
}

.notFoundSvg {
  @apply flex flex-col justify-center items-center w-full h-auto self-center mt-[4rem];
  .svgs404 {
    @apply self-center items-center;
  }
  .btnToHome {
    @apply self-center;
    button {
      @apply px-6 py-3 rounded-lg;
      border: 2px solid #29d7df;
    }
  }
}
